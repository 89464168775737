@keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }


  .spinner-container{
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-x: hidden;
    
    > .loading-spinner {
      width: 40px;
      height: 40px;
      border: 5px solid #f3f3f3; /* Light grey */
      border-top: 5px solid red; /* Black */
      border-radius: 50%;
      animation: spinner 1.5s linear infinite;
    }
  }
  