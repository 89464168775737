@import "../utils/Colors.scss";

.contact {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: right;
  overflow-x: hidden;
 

  > form {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    padding: 2rem;
    width: 40%;
    height: 100%;
    justify-content: center;
    > h2 {
      font-size: 1.5rem;
      text-transform: uppercase;
      font-family: "Roboto";
      margin: 2rem 0;
    }
>p{
  color:$red;
  padding: 0;
  margin: 0;
  text-align: left;
  width: 75%;
  margin-bottom: 10px;
}
    > input,
    > textarea,
    > button {
      padding: 1rem;
      outline: none;
      border: 1px solid rgb(202, 202, 202);
      margin: 0.5rem;
      width: 70%;
      border-radius: 5px;
    }

    > button {
      background-color: $red;
      border: none;
      cursor: pointer;
      color: white;
      font-size: 1rem;
      width: 75%;

      &:hover {
        background-color: $reddark;
      }
    }
  }

  .formBorder {
    width: 200px;
    height: 80%;
    background-color: white;
    border-radius: 0 200px 200px 0;
    position: relative;
    > div {
      width: 200px;
      height: 200px;
      position: absolute;
  
      transform: translate(50%, -50%);
      filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.566));
      > img {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .contact {
    height: 70vh;
    > form {
      width: 100%;
      height: 100%;


      >img{
        width: 100;
      }
    }
    >img{
      display: none;
    }
    .formBorder {
      display: none;
    }
  }
}