@import "../utils/Colors.scss";

.privacy {
    width: 100%;
    display: flex;
    overflow-x: hidden;
    flex-direction: column;
    margin-top: 5%;
    margin-left: 10%;
    margin-right: 10%;
    h2 {
        font-size: 1.5rem;
        text-transform: uppercase;
        font-family: "Roboto";
        align-items: flex-start;
       

        
       
      }
      > p{
        align-items: 'center';
        justify-content: 'center';
        font-family: "Roboto";
        width: 80%;
        margin-top: 2%;
        text-align: justify;
        justify-content:'ceter';
        margin-top: 2000;
        opacity: 0.7;

       
      }
}